<template>
  <v-container
    id="about"
    tag="section"
  >
    <base-subheading>About Us</base-subheading>

    <p>
      JollyTravelCancun it is an expert organization in receptive tourism, which through the commitment to the integrity of its people offers you the safest and fastest way to reach your destination
    </p>

    <base-subheading>Prevention Program COVID-19</base-subheading>
    <p>JTC is working diligently to take all necessary action to provide the healthiest environment for our transportation services.</p>
    <p>These actions include:</p>
    <ul>
      <li>Only Privates services will be offered to encourage social distancing by parties</li>
      <li>Mandatory face mask use for all our customer-facing employees</li>
      <li>Hand sanitation procedure in place before boarding (Alcohol based)</li>
      <li>After each service our vans will be sanitized</li>
      <li>All employee health screening with temperature check on arrival</li>
      <li>We will ask all our passenger to wear a face mask</li>
    </ul>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeAbout',
  }
</script>
